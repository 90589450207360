exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-blog-crab-mdx": () => import("./../../../src/pages/blog/crab.mdx" /* webpackChunkName: "component---src-pages-blog-crab-mdx" */),
  "component---src-pages-blog-home-mdx": () => import("./../../../src/pages/blog/home.mdx" /* webpackChunkName: "component---src-pages-blog-home-mdx" */),
  "component---src-pages-blog-life-mdx": () => import("./../../../src/pages/blog/life.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-users-contentful-author-slug-js": () => import("./../../../src/pages/users/{ContentfulAuthor.slug}.js" /* webpackChunkName: "component---src-pages-users-contentful-author-slug-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */)
}

